
import { Action } from '../api/src/models/Action';
import uiMixin from '../mixins/ui';

// https://help.apple.com/app-store-connect/#/devd274dd925

export default {
  name: 'DemoData',
  mixins: [
    uiMixin,
  ],
  data() {
    return {
      interests: [
        {
          name: 'personal',
        },
        {
          name: 'home',
        },
        {
          name: 'friends & family',
        },
        {
          name: 'community',
        },
        {
          name: 'work',
        },
        {
          name: 'school',
        },
      ],
      demoStates: {
        default: {
          actions: [
            {
              description: 'Buy a gift for Jason',
              status: Action.Status.enum.Done,
              value: 18,
              meta: {
                interestName: 'friends & family',
              },
            },
            {
              description: 'Call Mom',
              status: Action.Status.enum.Done,
              value: 18,
              meta: {
                interestName: 'friends & family',
              },
            },
            {
              description: 'Take out the trash',
              status: Action.Status.enum.Done,
              value: 14,
              meta: {
                interestName: 'home',
              },
            },
            {
              description: 'Go grocery shopping',
              status: Action.Status.enum.Done,
              value: 25,
              meta: {
                interestName: 'home',
              },
            },
            {
              description: 'Write first draft of term paper',
              status: Action.Status.enum.Done,
              value: 17,
              meta: {
                interestName: 'school',
              },
            },
            {
              description: 'Submit thesis statement for Poli-Sci',
              status: Action.Status.enum.Done,
              value: 22,
              meta: {
                interestName: 'school',
              },
            },
            {
              description: 'Do 10 pushups',
              frequency: Action.Frequency.enum.Day,
              importance: Action.EffortImportance.ImportanceMaxValue,
              meta: {
                interestName: 'personal',
                addOccurrences: 6,
              },
            },
            {
              description: 'Schedule meeting with James & Rich',
              status: Action.Status.enum.Done,
              value: 28,
              meta: {
                interestName: 'work',
              },
            },
            {
              description: 'Attend PTA meeting',
              status: Action.Status.enum.Done,
              value: 26,
              timeframe: [
                new Date(),
                null,
              ],
              meta: {
                interestName: 'community',
              },
            },
          ],
        },
        complete: {
          actions: [
            {
              description: 'Call Mom',
              status: Action.Status.enum.Done,
              value: 14,
              meta: {
                interestName: 'friends & family',
              },
            },
            {
              description: 'Take out the trash',
              status: Action.Status.enum.Done,
              value: 14,
              meta: {
                interestName: 'home',
              },
            },
            {
              description: 'Write first draft of term paper',
              status: Action.Status.enum.Done,
              value: 9,
              meta: {
                interestName: 'school',
              },
            },
            {
              description: 'Do 10 pushups',
              frequency: Action.Frequency.enum.Day,
              importance: Action.EffortImportance.ImportanceMaxValue,
              meta: {
                interestName: 'personal',
                addOccurrences: 7,
              },
            },
            {
              description: 'Schedule meeting with James & Rich',
              status: Action.Status.enum.Done,
              value: 28,
              meta: {
                interestName: 'work',
              },
            },
            {
              description: 'Attend PTA meeting',
              status: Action.Status.enum.Done,
              value: 26,
              timeframe: [
                new Date(),
                null,
              ],
              meta: {
                interestName: 'community',
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async clearData() {
      let result = false;
      const isClearing = await this.uiMsgBoxConfirm('Are you sure you want to clear all data for this account? This cannot be undone.');

      if (isClearing) {
        result = await this.$store.dispatch('actionsUser/deleteAllItems');

        if (result) {
          this.uiNotify('Actions deleted!');
        }

        result = result && await this.$store.dispatch('interests/deleteAllItems');

        if (result) {
          this.uiNotify('Interests deleted!');
        }

        if (!result) {
          this.uiMsgBoxOk('Failed to delete all items.');
        }
      }

      return result;
    },
    async replaceData(stateName) {
      let result = false;
      const isReplacing = await this.uiMsgBoxConfirm('Are you sure you want to replace all data for this account? This cannot be undone.');

      if (isReplacing) {
        const interestsProm = [];

        this.interests.forEach((i) => {
          interestsProm.push(this.$store.dispatch('interests/editItem', {
            id: undefined,
            mods: i,
          }));
        });

        await Promise.all(interestsProm);
        this.uiNotify('Added replacement interests');

        if (this.demoStates[stateName] && Array.isArray(this.demoStates[stateName].actions)) {
          const actionsProm = [];
          const d = new Date();

          // add some random history
          for (let daysAgo = this.$store.state.recentDaysCount; daysAgo >= 1; daysAgo--) {
            const count = this.$store.getters.getRandomInt(`${Date.now()}${daysAgo}`, 7);
            const d2 = this.$store.getters.virtualDay.subtract(daysAgo, 'days').toDate();

            for (let i = 0; i < count; i++) {
              const interestIndex = this.$store.getters.getRandomInt(`${Date.now()}${daysAgo}`, this.interests.length - 1);
              actionsProm.push(
                this.$store.dispatch('actionsUser/editItem', {
                  id: undefined,
                  mods: {
                    description: `Random item ${daysAgo}-${i}`,
                    interest: [this.$store.getters['interests/getInterestByName'](this.interests[interestIndex].name).id],
                    lastProgressAt: d2,
                    status: Action.Status.enum.Done,
                    value: 28,
                  },
                })
              );
            }
          }

          this.demoStates[stateName].actions.forEach((i) => {
            const item = { ...i };
            const addOccurrences = Number(item.meta.addOccurrences);

            item.interest = [
              this.$store.getters['interests/getInterestByName'](i.meta.interestName).id,
            ];

            delete item.meta;

            if (item.status === Action.Status.enum.Done) {
              item.lastProgressAt = d;
            }

            actionsProm.push(new Promise((resolve) => {
              this.$store.dispatch('actionsUser/editItem', {
                id: undefined,
                mods: item,
              }).then((item) => {
                if (!isNaN(addOccurrences) && addOccurrences > 0) {
                  // today is 0 days ago
                  const daysAgoStop = this.$store.state.recentDaysCount - addOccurrences;

                  for (let daysAgo = this.$store.state.recentDaysCount; daysAgo >= 0; daysAgo--) {
                    if (daysAgo > daysAgoStop) {
                      const d = this.$store.getters.virtualDay.subtract(daysAgo, 'days').toDate();
                      actionsProm.push(this.$store.dispatch('actionsUser/addRepeatingItemOccurrenceCompleted', {
                        ...item,
                        lastProgressAt: d,
                      }));
                    }
                  }
                }
              }).then(resolve);
            }));
          });

          await Promise.all(actionsProm);
          this.uiNotify('Added replacement actions');

          result = true;
        }
      }

      return result;
    },
    async clearDataAndReplace(stateName) {
      let result = false;

      await this.$store.dispatch('userSettings/setEntitlementIsLifetime');
      const didClear = await this.clearData();

      if (didClear) {
        result = await this.replaceData(stateName);
      }

      return result;
    },
  },
};
